import { useMemo } from 'react';

import type { JSONDocNode } from '@atlaskit/editor-json-transformer';

import { useSessionData } from '@confluence/session-data';
import { getRendererInlineCommentOptions } from '@confluence/annotation-provider-inline-comments';
import { useAnnotationsDispatch } from '@confluence/annotation-provider-store';

export type RendererAnnotationProviderOptions = {
	pageId: string;
	validAnnotationsCallback: (validAnnotations: string[]) => void;
	triggerAnalyticsEvent: any;
	updateDocument: (adfDoc: JSONDocNode) => void;
	isArchived?: boolean;
};

export const useRendererAnnotationProviderOptions = ({
	pageId,
	validAnnotationsCallback,
	triggerAnalyticsEvent,
	updateDocument,
	isArchived,
}: RendererAnnotationProviderOptions) => {
	const { setSelectionProps, setViewProps, setHoverProps } = useAnnotationsDispatch();

	const { featureFlagClient } = useSessionData();

	return useMemo(
		() => ({
			inlineComment: getRendererInlineCommentOptions({
				pageId,
				validAnnotationsCallback,
				triggerAnalyticsEvent,
				setSelectionProps,
				setHoverProps,
				setViewProps,
				updateDocument,
				isArchived,
				allowCommentsOnMedia: featureFlagClient.getBooleanValue(
					'confluence.frontend.renderer.comments-on-media',
					{ default: false },
				),
			}),
		}),
		[
			pageId,
			validAnnotationsCallback,
			triggerAnalyticsEvent,
			setSelectionProps,
			setHoverProps,
			setViewProps,
			updateDocument,
			isArchived,
			featureFlagClient,
		],
	);
};
