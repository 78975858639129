import type { JSONDocNode } from '@atlaskit/editor-json-transformer';
import type {
	InlineCommentSelectionComponentProps,
	InlineCommentViewComponentProps,
	InlineCommentHoverComponentProps,
} from '@atlaskit/editor-common/types';

import { getRendererAnnotationEventEmitter } from '@confluence/annotation-event-emitter';
import { getRendererAnnotationStates } from '@confluence/comments-util/entry-points/stateResolver';

import type { HoverComponentProps } from './HoverComponent';
import type { ViewComponentProps } from './ViewComponent';
import { SelectionManager, type BaseSelectionComponentProps } from './SelectionManager';
import { ViewManager } from './ViewManager';
import { HoverManager } from './HoverManager';

type getRendererInlineCommentOptionsProps = {
	pageId: string;
	validAnnotationsCallback: (validAnnotations: string[]) => void;
	triggerAnalyticsEvent: any;
	setSelectionProps: (
		selectionProps: (InlineCommentSelectionComponentProps & BaseSelectionComponentProps) | null,
	) => void;
	setHoverProps: (
		hoverProps: (InlineCommentHoverComponentProps & HoverComponentProps) | null,
	) => void;
	setViewProps: (viewProps: (InlineCommentViewComponentProps & ViewComponentProps) | null) => void;
	updateDocument?: (doc: JSONDocNode) => void;
	isArchived?: boolean;
	allowCommentsOnMedia?: boolean;
};

export const getRendererInlineCommentOptions = ({
	pageId,
	validAnnotationsCallback,
	triggerAnalyticsEvent,
	setSelectionProps,
	setViewProps,
	updateDocument,
	isArchived,
	setHoverProps,
	allowCommentsOnMedia,
}: getRendererInlineCommentOptionsProps) => {
	let updateSubscriber = getRendererAnnotationEventEmitter();
	if (!updateSubscriber?.off) {
		updateSubscriber = undefined;
	}

	return {
		selectionComponent: SelectionManager({
			pageId,
			updateDocument,
			isArchived,
			setSelectionProps,
		}),
		viewComponent: ViewManager({
			pageId,
			updateDocument,
			isArchived,
			setViewProps,
		}),
		hoverComponent: HoverManager({
			pageId,
			updateDocument,
			isArchived,
			setHoverProps,
		}),
		getState: (annotations: string[], isNestedRender: boolean) =>
			getRendererAnnotationStates({
				annotations,
				pageId,
				validAnnotationsCallback,
				triggerAnalyticsEvent,
				isNestedRender,
			}),
		updateSubscriber,
		disallowOnWhitespace: true,
		isToolbarAbove: true,
		allowDraftMode: true,
		allowCommentsOnMedia,
	};
};
