import { useMemo } from 'react';

import { getAGGClient, useQueryNoFurtherUpdate } from '@confluence/graphql';
import { useSessionData } from '@confluence/session-data';
import { useContentType, usePageContentId } from '@confluence/page-context';
import { fg } from '@confluence/feature-gating';

import { HighlightKeywordsQuery } from './gql/HighlightKeywordsQuery.agggraphql';
import { generatePageARI, generateWorkspaceId } from './reading-aids-utils';
import {
	type HighlightKeywordsQueryType,
	type HighlightKeywordsQueryVariables,
	KeyPhraseCategory,
} from './gql/__types__/HighlightKeywordsQuery';

type useHighlightAcronymsArgs = {
	skipNetworkCall?: boolean;
};

export type HighlightPhraseNodeType = {
	phrase: string;
	category: KeyPhraseCategory;
};

type HighlightAcronymsType = {
	acronyms: HighlightPhraseNodeType[];
	acronymRegex: RegExp;
	loading: boolean;
};

const cachedAcronymRegex = {
	pageARI: '',
	regex: new RegExp('(?!)'),
};

const getAcronymRegex = (
	acronyms: HighlightPhraseNodeType[],
	pageARI: string = '',
	ignoreCase: boolean = false,
): RegExp => {
	if (pageARI === cachedAcronymRegex.pageARI) {
		return cachedAcronymRegex.regex;
	}
	let regex;
	try {
		regex = new RegExp(
			acronyms.map((node) => `\\b${node.phrase}\\b`).join('|'),
			ignoreCase ? 'gi' : 'g',
		);
	} catch (_e) {
		regex = new RegExp('(?!)');
	}
	cachedAcronymRegex.pageARI = pageARI;
	cachedAcronymRegex.regex = regex;
	return regex;
};

export const useHighlightAcronyms = ({
	skipNetworkCall = false,
}: useHighlightAcronymsArgs): HighlightAcronymsType => {
	const [contentId] = usePageContentId();
	const [contentType] = useContentType();
	const { cloudId, activationId } = useSessionData();
	const pageARI = contentId ? generatePageARI(contentId, cloudId, contentType) : undefined;
	const workspaceId = generateWorkspaceId(activationId, cloudId);

	const { data, loading, error } = useQueryNoFurtherUpdate<
		HighlightKeywordsQueryType,
		HighlightKeywordsQueryVariables
	>(HighlightKeywordsQuery, {
		variables: {
			pageARI,
			workspaceId,
			keywordsEnabled: fg('platform_enable-confluence-key-phrases'),
		},
		errorPolicy: 'all',
		skip: !workspaceId || !pageARI || skipNetworkCall,
		client: getAGGClient(),
	});

	const acronyms = useMemo(() => {
		return !loading && !error && data?.getKeywords
			? data.getKeywords.map((keyword) => ({
					phrase: keyword,
					category: KeyPhraseCategory.ACRONYM,
				}))
			: [];
	}, [data, loading, error]);

	const keyPhrases = useMemo(() => {
		return !loading && !error && data?.knowledgeDiscovery?.keyPhrases?.nodes
			? data.knowledgeDiscovery.keyPhrases.nodes.map((node) => ({
					phrase: node.keyPhrase,
					category: node.category,
				}))
			: [];
	}, [data, loading, error]);

	const acronymRegexMemo = useMemo(() => {
		let acronymRegex = new RegExp('(?!)');
		if (keyPhrases.length && fg('platform_enable-confluence-key-phrases')) {
			acronymRegex = getAcronymRegex(keyPhrases, pageARI, true);
		} else if (acronyms.length) {
			acronymRegex = getAcronymRegex(acronyms, pageARI);
		}
		return acronymRegex;
	}, [acronyms, pageARI, keyPhrases]);

	return useMemo(
		() => ({
			acronyms: fg('platform_enable-confluence-key-phrases') ? keyPhrases : acronyms,
			acronymRegex: acronymRegexMemo,
			loading,
		}),
		[acronyms, acronymRegexMemo, loading, keyPhrases],
	);
};
