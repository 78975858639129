export interface HighlightKeywordsQueryVariables {
  pageARI?: string;
  workspaceId: string;
  keywordsEnabled: boolean;
}

export enum KeyPhraseCategory {
  PROJECT = "PROJECT",
  TEAM = "TEAM",
  ACRONYM = "ACRONYM",
  OTHER = "OTHER",
}

export interface HighlightKeywordsQueryType {
  getKeywords: string[] | null;
  knowledgeDiscovery: {
    keyPhrases: {
      nodes: {
        keyPhrase: string;
        category: KeyPhraseCategory;
      }[];
    };
  };
}
